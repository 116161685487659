export const familyImages = [
    {
        src: '../img/family/Candies_MaternityShoot_Morgan_0322.jpg',
        title: 'Image1',
        description: '',
    },
    {
        src: '../img/family/Candies_MaternityShoot_Morgan_0345.jpg',
        title: 'Image2',
        description: '',
    },

    {
        src: '../img/family/Candies_MaternityShoot_Morgan_0124.jpg',
        title: 'Image3',
        description: '',
    },
    {
        src: '../img/family/_ADR0236.jpg',
        title: 'Image4',
        description: '',
    },
    {
        src: '../img/family/_ADR0366.jpg',
        title: 'Image5',
        description: '',
    },
    {
        src: '../img/family/_ACG7477.jpg',
        title: 'Image6',
        description: '',
    },
    {
        src: '../img/family/_ACG7582.jpg',
        title: 'Image7',
        description: '',
    },
    {
        src: '../img/family/_ACG7697.jpg',
        title: 'Image8',
        description: '',
    },
    {
        src: '../img/family/_ACG8050.jpg',
        title: 'Image9',
        description: '',
    },
    {
        src: '../img/family/_ACG8225.jpg',
        title: 'Image10',
        description: '',
    },
    {
        src: '../img/family/_ACG7173.jpg',
        title: 'Image11',
        description: '',
    },
    {
        src: '../img/family/_ACG6997.jpg',
        title: 'Image12',
        description: '',
    },
    {
        src: '../img/family/_ACG6978.jpg',
        title: 'Image13',
        description: '',
    },
    {
        src: '../img/family/_ACG6528.jpg',
        title: 'Image14',
        description: '',
    },
    {
        src: '../img/family/_ACG6707.jpg',
        title: 'Image15',
        description: '',
    },
    {
        src: '../img/family/_ACG6573.jpg',
        title: 'Image16',
        description: '',
    },
];
