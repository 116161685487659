export const weddingImages = [
    {
        src: '../img/weddings/wedding1.jpg',
        title: 'Image1',
        description: '',
    },
    {
        src: '../img/weddings/wedding2.jpg',
        title: 'Image1',
        description: '',
    },
    {
        src: '../img/weddings/wedding3.jpg',
        title: 'Image1',
        description: '',
    },
    {
        src: '../img/weddings/wedding4.jpg',
        title: 'Image1',
        description: '',
    },
    {
        src: '../img/weddings/wedding5.jpg',
        title: 'Image1',
        description: '',
    },
    {
        src: '../img/weddings/wedding6.jpg',
        title: 'Image1',
        description: '',
    },
    {
        src: '../img/weddings/wedding7.jpg',
        title: 'Image1',
        description: '',
    },
    {
        src: '../img/weddings/wedding8.jpg',
        title: 'Image1',
        description: '',
    },
    {
        src: '../img/weddings/wedding9.jpg',
        title: 'Image1',
        description: '',
    },
    {
        src: '../img/weddings/ACG_0004.jpg',
        title: 'Image1',
        description: '',
    },
    {
        src: '../img/weddings/ACG_0005.jpg',
        title: 'Image2',
        description: '',
    },

    {
        src: '../img/weddings/ACG_0007.jpg',
        title: 'Image3',
        description: '',
    },
    {
        src: '../img/weddings/AdrianCGarciaWeddings_0027.jpg',
        title: 'Image4',
        description: '',
    },
    {
        src: '../img/weddings/AdrianCGarciaWeddings_0028.JPG',
        title: 'Image5',
        description: '',
    },
    {
        src: '../img/weddings/AdrianCGarciaWeddings_0032.JPG',
        title: 'Image6',
        description: '',
    },
    {
        src: '../img/weddings/AdrianCGarciaWeddings_0036.jpg',
        title: 'Image7',
        description: '',
    },
    {
        src: '../img/weddings/AdrianCGarciaWeddings_0038.jpg',
        title: 'Image8',
        description: '',
    },
    {
        src: '../img/weddings/AdrianCGarciaWeddings_0044.jpg',
        title: 'Image9',
        description: '',
    },
    {
        src: '../img/weddings/AdrianCGarciaWeddings_0046.jpg',
        title: 'Image10',
        description: '',
    },
    {
        src: '../img/weddings/AdrianCGarciaWeddings_0047.jpg',
        title: 'Image11',
        description: '',
    },
    {
        src: '../img/weddings/AdrianCGarciaWeddings_0050.jpg',
        title: 'Image12',
        description: '',
    },
    // {
    //     src: '../img/weddings/image-7.jpg',
    //     title: 'Image13',
    //     description: '',
    // },
    // {
    //     src: '../img/weddings/image-8.jpg',
    //     title: 'Image14',
    //     description: '',
    // },
];
