export const headshotsImages = [
 
    {
        src: '../img/headshots/Nina6.JPG',
        title: 'Image5',
        description: '',
    },
    {
        src: '../img/headshots/ACG_David_Lozano_0015.jpg',
        title: 'Image7',
        description: '',
    },
    {
        src: '../img/headshots/ACG_EC122.jpg',
        title: 'Image8',
        description: '',
    },
    {
        src: '../img/headshots/ACG_EC128.jpg',
        title: 'Image9',
        description: '',
    },
    {
        src: '../img/headshots/ACG_EC132.jpg',
        title: 'Image10',
        description: '',
    },
    {
        src: '../img/headshots/ACG_EC145.jpg',
        title: 'Image11',
        description: '',
    },
    {
        src: '../img/headshots/ACG_EC158.jpg',
        title: 'Image12',
        description: '',
    },
    {
        src: '../img/headshots/ACG_EC161.jpg',
        title: 'Image13',
        description: '',
    },
    {
        src: '../img/headshots/ACG_EC166.jpg',
        title: 'Image14',
        description: '',
    },
    {
        src: '../img/headshots/ACG_EC175.jpg',
        title: 'Image15',
        description: '',
    },
    {
        src: '../img/headshots/ACG_EC185.jpg',
        title: 'Image16',
        description: '',
    },
    {
        src: '../img/headshots/ACG_EC186.jpg',
        title: 'Image17',
        description: '',
    },
    {
        src: '../img/headshots/headshot1.jpg',
        title: 'headshot1',
        description: '',
    },
    {
        src: '../img/headshots/headshot2.jpg',
        title: 'headshot2',
        description: '',
    },
    {
        src: '../img/headshots/headshot3.jpg',
        title: 'headshot3',
        description: '',
    },
    {
        src: '../img/headshots/headshot4.jpg',
        title: 'headshot4',
        description: '',
    },

    {
        src: '../img/headshots/headshot5.jpg',
        title: 'headshot5',
        description: '',
    },
    // {
    //     src: '../img/headshots/headshot6.jpg',
    //     title: 'headshot6',
    //     description: '',
    // },
    {
        src: '../img/headshots/headshot7.jpg',
        title: 'headshot7',
        description: '',
    },
    // {
    //     src: '../img/headshots/headshot8.jpg',
    //     title: 'headshot8',
    //     description: '',
    // },
    {
        src: '../img/headshots/headshot9.jpg',
        title: 'headshot9',
        description: '',
    },
    {
        src: '../img/headshots/headshot10.jpg',
        title: 'headshot12',
        description: '',
    },
    {
        src: '../img/headshots/headshot11.jpg',
        title: 'headshot11',
        description: '',
    },
    {
        src: '../img/headshots/headshot12.jpg',
        title: 'headshot12',
        description: '',
    },
];
