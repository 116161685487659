export const editorialImages = [
    {
        src: '../img/editorial/JaredPayton1.jpg',
        title: 'JaredPayton1',
        description: '',
    },
    {
        src: '../img/editorial/JaredPayton2.jpg',
        title: 'JaredPayton2',
        description: '',
    },

    {
        src: '../img/editorial/JaredPayton3.jpg',
        title: 'JaredPayton3',
        description: '',
    },
    {
        src: '../img/editorial/Rio1.jpg',
        title: 'Rio1',
        description: '',
    },
    {
        src: '../img/editorial/Rio2.jpg',
        title: 'Rio2',
        description: '',
    },
    {
        src: '../img/editorial/Rio3.jpg',
        title: 'Rio3',
        description: '',
    },
    {
        src: '../img/editorial/Rio4.jpg',
        title: 'Rio4',
        description: '',
    },
    {
        src: '../img/editorial/Nina3.jpg',
        title: 'Nina3',
        description: '',
    },
    {
        src: '../img/editorial/Nina4.jpg',
        title: 'Nina4',
        description: '',
    },
    {
        src: '../img/editorial/Nina5.jpg',
        title: 'Nina5',
        description: '',
    },
    {
        src: '../img/editorial/Caylei1.jpg',
        title: 'Caylei1',
        description: '',
    },
    {
        src: '../img/editorial/Caylei2.jpg',
        title: 'Caylei2',
        description: '',
    },
    {
        src: '../img/editorial/Caylei3.jpg',
        title: 'Caylei3',
        description: '',
    },
    {
        src: '../img/editorial/Mike1.jpg',
        title: 'Mike1',
        description: '',
    },
];
