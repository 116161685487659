export const maternityImages = [
    {
        src: '../img/maternity/KV1.jpg',
        title: 'Image1',
        description: '',
    },
    {
        src: '../img/maternity/KV3.jpg',
        title: 'Image2',
        description: '',
    },
    {
        src: '../img/maternity/KV6.jpg',
        title: 'Image3',
        description: '',
    },
    {
        src: '../img/maternity/KV8.jpg',
        title: 'Image4',
        description: '',
    },
    {
        src: '../img/maternity/KV9.jpg',
        title: 'Image5',
        description: '',
    },
    {
        src: '../img/maternity/KV10.jpg',
        title: 'Image6',
        description: '',
    },
    {
        src: '../img/maternity/_ACG1135.jpg',
        title: 'Image7',
        description: '',
    },

    {
        src: '../img/maternity/_ACG1257.jpg',
        title: 'Image8',
        description: '',
    },
    {
        src: '../img/maternity/_ACG1319.jpg',
        title: 'Image9',
        description: '',
    },
    {
        src: '../img/maternity/_ACG1761.JPG',
        title: 'Image10',
        description: '',
    },
    {
        src: '../img/maternity/_ACG1811.JPG',
        title: 'Image11',
        description: '',
    },
    {
        src: '../img/maternity/_ADR0110.jpg',
        title: 'Image12',
        description: '',
    },
    {
        src: '../img/maternity/_ADR0361.jpg',
        title: 'Image13',
        description: '',
    },
    {
        src: '../img/maternity/ACG_Jade_Maternity_Proof_0002.jpg',
        title: 'Image14',
        description: '',
    },
    {
        src: '../img/maternity/ACG_Jade_Maternity_Proof_0003.jpg',
        title: 'Image15',
        description: '',
    },
    {
        src: '../img/maternity/ACG_Jade_Maternity_Proof_0004.jpg',
        title: 'Image16',
        description: '',
    },
    {
        src: '../img/maternity/meia_0003.jpg',
        title: 'Image17',
        description: '',
    },
    {
        src: '../img/maternity/Meia_BabyBump_MilkBath0003.jpg',
        title: 'Image18',
        description: '',
    },
    {
        src: '../img/maternity/Meia_BabyBump_PhoenixGardens_0005.jpg',
        title: 'Image19',
        description: '',
    },
    {
        src: '../img/maternity/Meia_BabyBump_PhoenixGardens_0006.jpg',
        title: 'Image20',
        description: '',
    },
    {
        src: '../img/maternity/Meia.jpg',
        title: 'Image21',
        description: '',
    },
];
